import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-3">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <p className="mb-0">&copy; {new Date().getFullYear()} StickerForYou</p>
            <p className="mb-0">Email: <a href="mailto:stickforyou9621@gmail.com" className="text-white">stickforyou9621@gmail.com</a></p>
            <p className="mb-0">Phone: <a href="tel:9764536604" className="text-white">9764536604</a></p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
