import React from 'react';

const AboutContact = () => {
  return (
    <main className="container mt-5">
      <div className="row">
        <div className="col-md-6">
          <h2>About Us</h2>
          <p>Sumit Kumar, the founder of StickForYou, brings together a wealth of knowledge in anime, video games, printing, and vinyl businesses. With a passion for creativity and innovation, StickForYou offers a diverse range of stickers, posters, and skins for laptops and mobile devices. Our products are designed to cater to the unique tastes and preferences of anime and gaming enthusiasts, as well as individuals seeking personalized and high-quality accessories.</p>
        </div>
        <div className="col-md-6">
          <h2>Contact Us</h2>
          <p>If you're interested in investing in our business or have any inquiries, please don't hesitate to get in touch:</p>
          <div className="contact-info">
            <div className="mb-3">
              <button className="btn btn-primary btn-lg btn-block" onClick={() => window.location.href = 'mailto:stickforyou9621@gmail.com'}>
                Email Us
              </button>
            </div>
            <div className="mt-3">
              <button className="btn btn-success btn-lg btn-block" onClick={() => window.location.href = 'tel:9764536604'}>
                Call Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AboutContact;
