import React from 'react';

const Header = () => {
  return (
    <header className="bg-dark text-white py-4 border-bottom border-3">
      <div className="container">
        <div className="row align-items-center">
          <div className="col">
            <h1 className="brand-name">StickForYou</h1>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
